.width-110 {
    max-width: 110px;
}
.width-160 {
    min-width: 160px;
}
input.input-none {
    background: none;
    border: 1px solid rgba(215, 215, 215, 0.61);
    border-radius: 4px;
    height: 18px;
    padding-left: 3px;
    font-size: 14px;
}
